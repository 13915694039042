<script setup lang="ts">
import type { ButtonGroupItemProps, Tooltip } from './types'

// OPTIONS
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<ButtonGroupItemProps>(), {
  name: undefined,
  tooltip: undefined,
  disabled: false,
  to: undefined,
  icon: undefined,
  exact: undefined,
})

// EMITS
const emit = defineEmits<{ (e: 'click', event: Event): void }>()

// ACTIVE
const attrs = useAttrs()
const active = computed(() => attrs['data-active'])

const disabled = computed(() => props.disabled || props.to === null)

const onClick = (event: MouseEvent) => {
  if (event.shiftKey || event.ctrlKey || event.metaKey) return
  emit('click', event)
}
</script>

<template>
  <Tooltip
    :content="disabled ? undefined : tooltip?.content"
    :placement="tooltip?.placement"
    :class="tooltip?.class"
    role="tooltip"
    :aria-describedby="disabled ? undefined : tooltip?.content"
  >
    <Button
      :class="[
        'flex flex-col items-center justify-center rounded-xl border border-transparent bg-transparent px-3 py-1 focus:text-black',
        disabled
          ? 'pointer-events-none opacity-40 hover:bg-transparent'
          : 'hover:bg-gray-750 focus:bg-gray-750 focus:ring-gray-550/80 focus:ring',
        active && 'border-gray-750 !bg-gray-800',
        $attrs.class,
      ]"
      :disabled="disabled"
      :to="to ?? undefined"
      @click="onClick"
    >
      <div
        :class="[
          'text-md flex items-center',
          active ? 'text-primary' : 'text-gray-100',
        ]"
      >
        <Icon v-if="icon" :name="icon" filled class="mr-1 flex self-center" />

        <slot />
      </div>
    </Button>
  </Tooltip>
</template>
